import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const navigationType = useNavigationType(); // Detects the type of navigation (PUSH, POP, REPLACE)

  useEffect(() => {
    // if (navigationType === 'PUSH') {
      // Scroll to the top only for new navigations (PUSH)
      window.scrollTo({ top: 0, behavior: 'instant' });
    // }
    
    // Do nothing for POP (back/forward navigation)
  }, [pathname, navigationType]);

  return null;
}
