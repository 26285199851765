import React, { useState } from 'react';
import Getintouch from '../Getintouch';
import Contact from '../Contact';
import Footer from '../Footer';
import Header from '../Header';
import Getintouch_new from '../Getintouch_new';


export default function Contact_website() {

  return (
   <div >
    <div className='getintouch_sh_new'>
   <Header/>
   <div className='pt-5'><Getintouch_new/></div></div>
   <Contact/>
   <Footer/>
   </div>
  );
}
