import React from 'react';
import Explore from '../Explore';
import Footer from '../Footer';
import Header from '../Header';
import Our_Services_new from '../Our_Services_new';

export default function Services() {

  return (
   <div id='service'>
    <div className='ourservice_sh_new '>
  <div className='pb-5 pb-0 '><Header/></div>
   <Our_Services_new/></div>
   <Explore/>
   <Footer/>
   </div>
  );
}
