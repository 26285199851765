import React, { useState } from "react";
import tick from "../assets/whychoose_1.svg";
import service from "../assets/whychoose_2.svg";
import process from "../assets/whychoose_3.svg";
import support from "../assets/whychoose_4.svg";
import internet from "../assets/whychoose_5.svg";


export default function Whychooseus() {
  return (
    <div className="whychoose_sh" >
      <div className="d-flex  whychoose_heading pb-1 pb-lg-4">
        <div className="d-none d-md-block"
          style={{
            height: "4px",
            width: "13.5%",
            backgroundColor: "#000",
            marginTop: "5rem",
            marginLeft: "8%",
            marginRight: "1%",
          }}
        ></div>
        <h1>Why Choose Us?</h1>
      </div>
      <div className="d-block  d-lg-flex justify-content-between whychoose_details_main py-5">

        <div className="whychoose_deatils ">
          <div className="whycoose_img_div"><img src={tick}></img></div>
          <h6 className="pt-3 pb-2">Proven Success</h6>
          <p>A strong record of successful applications.</p>
        </div>

        <div className="whychoose_deatils">
          <div className="whycoose_img_div"><img src={service}></img></div>
          <h6 className="pt-3 pb-2">Personalized Services</h6>
          <p>Tailored advice for your unique needs.</p>
        </div>
        
        <div className="whychoose_deatils">
          <div className="whycoose_img_div"><img src={process}></img></div>
          <h6 className="pt-3 pb-2">Transparent Process</h6>
          <p>Clear and honest communication, no hidden fees.</p>
        </div>

        <div className="whychoose_deatils">
          <div className="whycoose_img_div"><img src={support}></img></div>
          <h6 className="pt-3 pb-2">End-to-End Support</h6>
          <p>Guidance from start to finish.</p>
        </div>

        <div className="whychoose_deatils">
          <div className="whycoose_img_div"><img src={internet}></img></div>
          <h6 className="pt-3 pb-2">Multilingual Team</h6>
          <p>Communicating in your preferred language for added ease.</p>
        </div>

       
      </div>
    </div>
  );
}
