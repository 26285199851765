import React, { useState } from "react";
import visa_1 from "../assets/visa_1.png"
import visa_2 from "../assets/visa_2.png"
import visa_3 from "../assets/visa_3.png"
import visa_4 from "../assets/visa_4.png"
import arrow from "../assets/arrow.svg"
import { Link } from "react-router-dom";


export default function Explorevisaprogram() {
  return (
    <div className="d-flex align-items-center justify-content-center explorevisaprogram_main_div">
    <div className="explorevisaprogram_sh  ">
      <h2 className="text-center pb-3 pb-md-5">Explore Top European Visa Programs</h2>
      <div>
      <div className="visa_program">
        <div className="visa_program_img_div"><img src={visa_1}></img></div>
        <h3>Luxembourg EU Blue Card</h3>
        <p>Designed for highly skilled professionals, this visa provides an opportunity to live and work in Luxembourg with attractive benefits and pathways to residency.</p>
        <Link to={"/luxembourg"} className="visa_program_link">Read more <img src={arrow} style={{width:"22px", height:"22px"}}></img></Link>
      </div>

      <div className="visa_program">
        <div className="visa_program_img_div"><img src={visa_2}></img></div>
        <h3>Germany Opportunity Card</h3>
        <p>A flexible visa option for skilled workers, offering a chance to explore job opportunities in Germany while meeting the country's economic demands.</p>
        <Link to={"/german"} className="visa_program_link">Read more <img src={arrow} style={{width:"22px", height:"22px"}}></img></Link>

      </div>

      <div className="visa_program">
        <div className="visa_program_img_div"><img src={visa_3}></img></div>
        <h3>Portugal Job Seeker Visa</h3>
        <p>Allows you to search for employment in Portugal while enjoying the country's vibrant culture and scenic landscapes. Ideal for skilled professionals.</p>
        <Link to={"/portugal"} className="visa_program_link">Read more <img src={arrow} style={{width:"22px", height:"22px"}}></img></Link>
      </div>

      <div className="visa_program">
        <div className="visa_program_img_div"><img src={visa_4}></img></div>
        <h3>Sweden Residence Permit</h3>
        <p>Grants the right to live and work in Sweden, providing a gateway to long-term residency in one of Europe' most innovative and sustainable countries.</p>
        <Link to={"/sweden"} className="visa_program_link">Read more <img src={arrow} style={{width:"22px", height:"22px"}}></img></Link>

      </div>
      </div>
      </div>
    </div>
  );
}
