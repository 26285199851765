import React, { useState } from 'react';
import Whychooseus from '../Whychooseus';
import Footer from '../Footer';
import Header from '../Header';


export default function Whychoose_website() {

  return (
   <div id='whychoose'>
     <Header/>
    <Whychooseus/>
    <Footer/>
   </div>
  );
}
