import React, { useState } from "react";
import whoweare_1 from "../assets/whoweare_1.png";
import whoweare_2 from "../assets/whoweare_2.png";
import whoweare_3 from "../assets/whoweare_3.png";
import whoweare_4 from "../assets/whoweare_4.png";
import whoweare_5 from "../assets/whoweare_5.png";
import whoweare_6 from "../assets/whoweare_6.png";
import { useNavigate } from "react-router-dom";

export default function Whoweare() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <div >
      <div className="d-flex align-items-center justify-content-center ">
        <div className="whoweare_sh ">
          <h2>Who We Are</h2>
          <p>
            We are a trusted migration consultancy, helping individuals and
            families realize their dreams of moving to Europe, UK and the rest
            of the world. With years of expertise and a commitment to
            excellence, we provide comprehensive guidance for all aspects of
            migration.
          </p>
          <button className="whoweare_contact_btn"onClick={() => handleNavigation("/contact")}>
            
              Contact Us
            
          </button>
        </div>
      </div>

      <div className="d-flex align-items-start  explore__image_main_div">
        <div className="img_div">
          <img src={whoweare_1}></img>
        </div>
        <div className="img_div">
          <img src={whoweare_2}></img>
        </div>
        <div className="img_div">
          <img src={whoweare_3}></img>
        </div>
        <div className="img_div">
          <img src={whoweare_4}></img>
        </div>
        <div className="img_div">
          <img src={whoweare_5}></img>
        </div>
        <div className="img_div">
          <img src={whoweare_6}></img>
        </div>
      </div>
    </div>
  );
}
