import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import linkdin from "../assets/linkdin.svg";
import fb from "../assets/facebook.svg";
import insta from "../assets/instagram.svg";
import map from "../assets/footer_map.svg";
import phone from "../assets/footer_phone.svg";
import mail from "../assets/footer_mail.svg";
import warning from "../assets/warning.svg";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer_main_div">
      <div className="footer_sh">
        <div style={{ width: "68px", height: "70px", overflow: "hidden" }}>
          <div
            onClick={() => navigate("/")}
            className="img_cls_sh"
            style={{ cursor: "pointer" }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>

        <div className="d-block d-lg-flex">
          <div className="w-100 lg-w-50">
            <div className="footer_item_01">
              <p className="py-4">
                We are a trusted migration consultancy, helping individuals and
                families realize their dreams of moving to Europe, UK, and the
                rest of the world. With years of expertise and a commitment to
                excellence, we provide comprehensive guidance for all aspects of
                migration.
              </p>
              <div className="footer_item_01_info">
                <div className="d-flex pb-3 footer_item_01_info__pointer">
                  <img src={map} alt="Map icon" />
                  <a
                    
                    className="text-decoration-none"
                  >
                    London, England
                  </a>
                </div>
                <div className="d-flex pb-3 footer_item_01_info__pointer">
                  <img src={phone} alt="Phone icon" />
                  <a href="tel:+447412827923" className="text-decoration-none">
                   +44-7412-827923 
                  </a>
                </div>
                <div className="d-flex pb-3 footer_item_01_info__pointer">
                  <img src={mail} alt="Mail icon" />
                  <a
                    href="mailto:hello@tawasolmigration.com"
                    className="text-decoration-none"
                  >
                    hello@tawasolmigration.com
                  </a>
                </div>
              </div>

              <div className="d-flex footer_social_media py-3">
                {/* <a className="pe-2">
                  <img src={linkdin} alt="LinkedIn" />
                </a> */}
                <a
                  href="https://www.facebook.com/tawasolmigrationconsultancy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pe-2"
                >
                  <img src={fb} alt="Facebook" />
                </a>
                {/* <a>
                  <img src={insta} alt="Instagram" />
                </a> */}
              </div>
            </div>
          </div>

          <div className="d-flex w-100 lg-w-50 pt-4 pt-lg-0">
            <div className="w-100 lg-w-25 quick_link_div">
              <h5 className="pb-2">Quick Links</h5>
              <div
                className="Link_cls_footer"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              >
                Home
              </div>
              <div
                className="Link_cls_footer"
                onClick={() => navigate("/about")}
                style={{ cursor: "pointer" }}
              >
                About Us
              </div>
              <div
                className="Link_cls_footer"
                onClick={() => navigate("/service")}
                style={{ cursor: "pointer" }}
              >
                Services
              </div>
              <div
                className="Link_cls_footer"
                onClick={() => navigate("/whychoose")}
                style={{ cursor: "pointer" }}
              >
                Why Choose Us
              </div>
              <div
                className="Link_cls_footer"
                onClick={() => navigate("/testimonials")}
                style={{ cursor: "pointer" }}
              >
                Testimonials
              </div>
              <div
                className="Link_cls_footer"
                onClick={() => navigate("/visaprogram")}
                style={{ cursor: "pointer" }}
              >
                Visa Programs
              </div>
              <div
                className="Link_cls_footer"
                onClick={() => navigate("/contact")}
                style={{ cursor: "pointer" }}
              >
                Contact Us
              </div>
            </div>

            <div className="w-100 lg-w-25 legal_div">
              <h5 className="pb-2">Legal</h5>
              <h6>Privacy Policy</h6>
              <h6>Terms & Conditions</h6>
              <h6>Disclaimer</h6>
            </div>
          </div>
        </div>

        <div className="d-flex warning_div">
          <img src={warning} alt="Warning" />
          <p>
            We provide migration consultancy services but do not guarantee visa
            approval. All applications are subject to immigration authorities'
            discretion
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center footer_bottom">
        <p>&copy; 2024 Tawasol Relocation Ltd. All rights reserved.</p>
      </div>
    </div>
  );
}
