import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ourservice_1 from "../assets/ourservice_1.png";
import ourservice_2 from "../assets/ourservice_2.png";
import ourservice_3 from "../assets/ourservice_3.png";
import ourservice_4 from "../assets/ourservice_4.png";
import ourservice_5 from "../assets/ourservice_5.png";

export default function Our_Services_new() {
  return (
    <div className="">
      <div className="d-flex ourservice_heading_main_div">
        <div
          className="d-none d-md-block"
          style={{
            height: "3px",
            width: "13.5%",
            backgroundColor: "#FFF",
            marginTop: "4.2rem",
            marginLeft: "8%",
            marginRight: "1%",
          }}
        ></div>
        <div className="ourservice_heading_div">
          <h1>Our Services</h1>
          <p>
            From visa applications to relocation support, we offer a full range
            of migration services designed to meet your specific needs.
          </p>
        </div>
      </div>
      <div className="scroll_container" >
      <Swiper 
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={4.5}
        
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        navigation
        breakpoints={{
          0:{ slidesPerView: 1 },
          768: { slidesPerView: 2.5 },
          992: { slidesPerView: 4.5 },

        }}
      >
        <SwiperSlide className="pb-5">
          <div className="service_item">
            <div className="service_item_img_div">
              <img src={ourservice_1} alt="Service" />
            </div>
            <h5>Visa Applications</h5>
            <p>
              Visa Applications: Work, student, family, and business visa
              support.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="service_item">
            <div className="service_item_img_div">
              <img src={ourservice_2} alt="Service" />
            </div>
            <h5>Residency Permits</h5>
            <p>Assistance with temporary or permanent residency.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="service_item">
            <div className="service_item_img_div">
              <img src={ourservice_3} alt="Service" />
            </div>
            <h5>Citizenship Assistance</h5>
            <p>Guidance for naturalization and dual citizenship.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="service_item">
            <div className="service_item_img_div">
              <img src={ourservice_4} alt="Service" />
            </div>
            <h5>Business Migration</h5>
            <p>Tailored solutions for entrepreneurs and investors.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="service_item">
            <div className="service_item_img_div">
              <img src={ourservice_5} alt="Service" />
            </div>
            <h5>Relocation Support</h5>
            <p>Help with housing, education, and settling in.</p>
          </div>
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
  );
}
