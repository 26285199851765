
import './App.css';
import Header from './components/Header';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Home from './components/Home';
import Whoweare from './components/Whoweare';
import Ourservices from './components/Ourservices';
import Explore from './components/Explore';
import Explorevisaprogram from './components/Explorevisaprogram';
import Getintouch from './components/Getintouch';
import Whychooseus from './components/Whychooseus';
import Whatourclientsay from './components/Whatourclientsay';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Luxembourg from './components/europ/Luxembourg';
import German from './components/europ/German';
import Portugal from './components/europ/Portugal';
import Sweden from './components/europ/Sweden';
import Website from './components/Website';
import ScrollToTop from './components/ScrollToTop';
import About from './components/website/About';
import Services from './components/website/Services';
import Whychoose_website from './components/website/Whychoose_website';
import Reviews from './components/website/Reviews';
import Visa from './components/website/Visa';
import Contact_website from './components/website/Contact_website';
import Main_web from './components/website/Main_web';


function App() {
  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        <Routes>
          <Route path='/' element ={<Main_web/>}/>
          <Route path='/about' element ={<About/>}/>
          <Route path='/service' element ={<Services/>}/>
          <Route path='/whychoose' element ={<Whychoose_website/>}/>
          <Route path='/testimonials' element ={<Reviews/>}/>
          <Route path='/visaprogram' element ={<Visa/>}/>
          <Route path='/contact' element ={<Contact_website/>}/>
          <Route path='/german' element ={<German/>}/>
          <Route path='/luxembourg' element ={<Luxembourg/>}/>
          <Route path='/portugal' element ={<Portugal/>}/>
          <Route path='/sweden' element ={<Sweden/>}/>
        
        
      {/* <Header/>
      <Home/>
      <Whoweare/>
      <Ourservices/>
      <Explore/>
      <Whychooseus/>
      <Whatourclientsay/>
      <Explorevisaprogram/>
      <Getintouch/>
      <Contact/>
      <Footer/>

      <Luxembourg/>
      <German/>
      <Portugal/> */}
      {/* <Sweden/> */}
      </Routes>
      </Router>
    </div>
  );
}

export default App;
