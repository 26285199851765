import React, { useState } from 'react';
import Whoweare from '../Whoweare';
import Footer from '../Footer';
import Header from '../Header';

export default function About() {

  return (
   <div >
    <Header/>
   <Whoweare/>
   <Footer/>
   </div>
  );
}
