import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import bg from '../../assets/sweden.png';

export default function Sweden() {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const navigate = useNavigate(); // Using the useNavigate hook
    
 
    useEffect(() => {
        // Create an image object to detect when the background image has loaded
        const img = new Image();
        img.src = bg;
        img.onload = () => setIsImageLoaded(true); // Set the state to true when the image loads
    }, []);

    // Function to handle navigation
    const handleNavigation = (path) => {
        navigate(path); // Navigate to the given path
    };

  return (
   <div className="image-loader-page">
            {!isImageLoaded ? (
                <div className="loading-placeholder">
                <div className="spinner"></div>
                <p>Loading...</p>
              </div>
              
            ) : (<div className='text-center country_home country_04_home'>
                <div className='pb-5'> <Header/></div>
                 <div className='d-flex align-items-center justify-content-center country_visa_back_btn_div'>
                     
                     <button className='country_visa_back_btn'
                      onClick={() => navigate(-1)}><FaArrowLeft/>Go back</button>
                     
                 </div>
                 <h1 className='px-2'>Sweden Residence Permit</h1>
                 <p className='px-2'>Grants the right to live and work in Sweden, providing a gateway to long-term <br className='d-none d-lg-block'></br>residency in one of Europe's most innovative and sustainable countries.</p>
             
                 <div className='d-flex align-items-center justify-content-center'>
                 <Link to="start_reading"><button className='country_visa_start_btn mx-2'>Start Reading</button></Link>
                     
                 </div>
                </div>)}
   

   <div className='country_content' id='start_reading'>
    <div className='py-4'>
        <h3 >Overview</h3>
        <p>This permit allows individuals to live and work in Sweden, offering a pathway to permanent residency in one of Europe's most innovative countries.</p>
    </div>

    <div className='py-4'>
        <h3>Eligibility Criteria</h3>
        <ul>
            <li>A valid job offer or employment contract in Sweden.</li>
            <li>Proof of sufficient income to support yourself and any accompanying family members.</li>
            <li>Comprehensive health insurance.</li>
        </ul>
    </div>

    <div className='py-4'>
        <h3>Key Benefits</h3>
        <ul>
            <li>Access to Sweden's top-notch healthcare and education systems.</li>
            <li>Family reunification with equal rights.</li>
            <li>Possibility of permanent residency after a few years.</li>
        </ul>
    </div>

    <div className='py-4'>
        <h3>How to Apply</h3>
        <p>Tawasol Relocation simplifies the application process, ensuring your success in obtaining a residence permit.</p>
    </div>

    <div className='country_content_start_btn_div d-flex align-items-center justify-content-center d-md-block'><button onClick={() => handleNavigation("/contact")}>Contact Us Now</button></div>
   </div>
   <Footer/>
   </div>
  );
}
