import React, { useState } from "react";
import phone from "../assets/phone_01.svg";
import email from "../assets/email_02.svg";
import location from "../assets/location_03.svg";

export default function Getintouch_new() {
  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start getintouch_heading pb-0 pb-md-3">
        <div className="d-none d-md-block"
          style={{
            height: "3px",
            width: "13.5%",
            backgroundColor: "#FFF",
            marginTop: "5.2rem",
            marginLeft: "8%",
            marginRight: "1%",
          }}
        ></div>
        <h1>Get in Touch</h1>
      </div>
      <div className="d-block d-lg-flex justify-content-between  getintouch_details_main_new ">
        <div className="getintouch_deatils pb-5 pb-md-0 text-center text-lg-start">
          <img src={phone}></img>
          <p className="pt-2 pb-1 pt-md-4 pb-md-3">Phone</p>
          <a href="tel:+447412827923" style={{ textDecoration: "none", color: "inherit" }}>
            <h6>+44-7412-827923</h6>
          </a>
        </div>

        <div className="getintouch_deatils pb-5 pb-md-0 text-center text-lg-start">
          <img src={email}></img>
          <p className="pt-2 pb-1 pt-md-4 pb-md-3">Email</p>
          <a href="mailto:hello@tawasolmigration.com" style={{ textDecoration: "none", color: "inherit" }}>
            <h6>hello@tawasolmigration.com</h6>
          </a>
        </div>

        <div className="getintouch_deatils pb-5 text-center text-lg-start">
          <img src={location}></img>
          <p className="pt-2 pb-1 pt-md-4 pb-md-3">Office Address</p>
           <a
            
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <h6 style={{ lineHeight: "34px" }}>
            London, England
            </h6>
          </a>
        </div>
      </div>
    </div>
  );
}
