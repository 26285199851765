import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import review_ from "../assets/review_.svg";
import review_star from "../assets/review_start.svg";
import reviewer_1 from "../assets/reviewer_1.jpg";
import reviewer_2 from "../assets/reviewer_2.jpg";
import reviewer_3 from "../assets/reviewer_3.jpg";

export default function Whatourclientsay() {



  return (
    <div className="review_sh">
      <div className="d-flex align-items-center justify-content-center review_heading_div pb-0 pb-md-4">
        <h1>What Our Clients Say</h1>
      </div>
      <div className="d-flex scroll_container_review" >
      <Swiper 
      className="review_buttons"
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        navigation
        breakpoints={{
          768: { slidesPerView: 2 },
          992: { slidesPerView: 3 },
        }}
      >
        <SwiperSlide className="pb-5 ">
        <div className="review_item">
          <div className="">
            <img src={review_} />
          </div>
          <p>
            Thanks to Tawasol Relocation, I successfully secured my UK work visa
            without any stress! Their team guided me at every step and made the
            process seamless. I couldn't have done it without them!
          </p>
          <div className="d-flex">
            <div className="reviewer_img_div">
              <img
                src={reviewer_1}
                alt="Review Image"
                className="img-fluid rounded"
              />
            </div>
            <div className="reviewer_div ps-3 mt-1">
              <p className="mb-1">Ahmed S., Software Engineer</p>
              <div className="d-flex reviewer_star_div">
                <img src={review_star} />
                <img src={review_star} />
                <img src={review_star} />
                <img src={review_star} />
                <img src={review_star} />
              </div>
            </div>
          </div>
        </div>
        </SwiperSlide>

        <SwiperSlide className="pb-5">
        <div className="review_item">
          <div className="">
            <img src={review_} />
          </div>
          <p>
            Our family's dream of moving to Europe became a reality with Tawasol
            Relocation. They handled everything from visas to finding the
            perfect school for our kids. Highly professional and reliable!
          </p>
          <div className="d-flex">
            <div className="reviewer_img_div">
              <img
                src={reviewer_2}
                alt="Review Image"
                className="img-fluid rounded"
              ></img>
            </div>
            <div className="reviewer_div ps-3 mt-1">
              <p className="mb-1">Fatima H., Family Migrant</p>
              <div className="d-flex reviewer_star_div">
                <img src={review_star}></img>
                <img src={review_star}></img>
                <img src={review_star}></img>
                <img src={review_star}></img>
                <img src={review_star}></img>
              </div>
            </div>
          </div>
        </div>
        </SwiperSlide>

        <SwiperSlide className="pb-5">
        <div className="review_item">
          <div className="">
            <img src={review_} />
          </div>
          <p>
            I was overwhelmed with the requirements for the Canadian residency
            permit, but Tawasol Relocation made it simple. Their expertise and
            dedication were impressive. I highly recommend their services!
          </p>
          <div className="d-flex">
            <div className="reviewer_img_div">
              <img
                src={reviewer_3}
                alt="Review Image"
                className="img-fluid rounded"
              ></img>
            </div>
            <div className="reviewer_div ps-3 mt-1">
              <p className="mb-1">Rajesh K., Entrepreneur</p>
              <div className="d-flex reviewer_star_div">
                <img src={review_star}></img>
                <img src={review_star}></img>
                <img src={review_star}></img>
                <img src={review_star}></img>
                <img src={review_star}></img>
              </div>
            </div>
          </div>
        </div>
        </SwiperSlide>

        
        

        </Swiper>
      </div>
    </div>
  );
}
