import React, { useState } from 'react';
import Explorevisaprogram from '../Explorevisaprogram';
import Footer from '../Footer';
import Header from '../Header';

export default function Visa() {

  return (
   <div>
   <div className='explorevisaprogram_main_div'> <Header/>
   <Explorevisaprogram/></div>
   <Footer/>
   </div>
  );
}
