import React, { useState } from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

export default function Explore() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="text-center explore_sh">
      <h2>Explore Your Options</h2>
      <p className="pt-4 pt-md-0">
        Take the first step toward building your dream life abroad. Let our
        experts <br className="d-none d-md-block"></br>guide you with
        personalized, hassle-free migration solutions.
      </p>
      <button
        className="explore_btn"
        onClick={() => handleNavigation("/contact")}
      >
        Get a Free Consultation Now
      </button>
    </div>
  );
  s;
}
