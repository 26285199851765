import React, { useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Review_new from '../Review_new';


export default function Reviews() {

  return (
   <div >
  <div className='review_sh_new'>
    <Header/>
   <div className='pt-5'><Review_new/></div>
   </div>
   <Footer/>
   </div>
  );
}
